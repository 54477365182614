.article {

  a {
    font-family: 'Roboto';
    font-size:14px;
  }

  h1 {
    margin: 10px 2px 0 2px;
  }

  p {
    margin: 0;
    a {
      &.category {
        font-size: 16px;
      }
    }
  }

  .article_img {
    max-width:100%;
    margin-top: 12px;
  }

  .media-body {
    h5 {
      margin: 0;
    }
    small {
      font-size:11px;
      color:#b1b1b1;
      a{
        font-size: 14px;
      }
    }

    p {
      margin:0;
    }
  }

  .articles_widget {
    h2 {
      font-size: 20px;
      font-weight: bold;
      color: $black-color;
      margin: 15px 0px;
    }

    h3 {
      margin: 10px;
      a {
        font-size: 16px;
      }
    }

    .noviewed_img {
      width:100%;
      height:200px;
      object-fit: cover;
    }

    .noviewed_video {
      width:100%;
      height:200px;
      line-height: 200px;
      text-align: center;
    }

    .thumb_article {
      min-height:290px;
      margin: -5px;
    }

  }

  @media screen and (max-width: 768px) {
    .topic-img {
      width:100%;
      height:200px;
      object-fit: cover;
    }

    .article_mobile {
      margin:0 -13px;

      h1 {
        padding-right: 10px;
        padding-left: 10px;
      }

      p {
        padding-right: 10px;
        padding-left: 10px;
      }
    }
  }
}

.news {
  h1 {
    margin:10px 0 0;
  }
  p {
    margin: 2px 0px;
  }
}