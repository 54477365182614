.comments {

  margin-bottom: 40px;

  .single-comment {
  margin-top:40px;
  }

  hr {
    margin-top: 10px;
    margin-bottom: 5px;
    border: 0;
    border-top: 1px solid #eee;
  }

  .card {
    margin:30px 0;
  }

  .media-heading {
    a {
      color: #6e6e6e;
      font-size: 12px;
      font-weight: 300;
    }
    span {
      color: #fff;
      font-size: 12px;
    }
    .edit-button {
      padding-left: 15px;
    }
  }
  .answer {
    .media-body {
      h6 {
        font-size: 14px;
        margin: 0;
      }
      small {
        font-size: 11px;
        color: #b1b1b1;

        a{
          font-size: 12px;
        }
      }

      p {
        font-size: 12px;
        margin: 0;
      }

      .actions {
        a {
          font-size:13px;
        }
      }

    }


    .media-heading {
      span {
        color: #fff;
        font-weight: 300;
      }

      a{
        color:#c9c9c9;
        font-size:12px;
        font-weight: normal;
      }
    }
  }
}