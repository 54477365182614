.ui-group-buttons .or{position:relative;float:left;width:.3em;height:1.3em;z-index:3;font-size:12px}
.ui-group-buttons .or:before{position:absolute;top:50%;left:50%;content:'or';background-color:#5a5a5a;margin-top:-.1em;margin-left:-.9em;width:1.8em;height:1.8em;line-height:1.55;color:#fff;font-style:normal;font-weight:400;text-align:center;border-radius:500px;-webkit-box-shadow:0 0 0 1px rgba(0,0,0,0.1);box-shadow:0 0 0 1px rgba(0,0,0,0.1);-webkit-box-sizing:border-box;-moz-box-sizing:border-box;-ms-box-sizing:border-box;box-sizing:border-box}
.ui-group-buttons .or:after{position:absolute;top:0;left:0;content:' ';width:.3em;height:2.84em;background-color:rgba(0,0,0,0);border-top:.6em solid #5a5a5a;border-bottom:.6em solid #5a5a5a}
.ui-group-buttons .or.or-lg{height:1.3em;font-size:16px}
.ui-group-buttons .or.or-lg:after{height:2.85em}
.ui-group-buttons .or.or-sm{height:1em}
.ui-group-buttons .or.or-sm:after{height:2.5em}
.ui-group-buttons .or.or-xs{height:.25em}
.ui-group-buttons .or.or-xs:after{height:1.84em;z-index:-1000}
.ui-group-buttons{display:inline-block;vertical-align:middle}
.ui-group-buttons:after{content:".";display:block;height:0;clear:both;visibility:hidden}
.ui-group-buttons .btn{float:left;border-radius:0}
.ui-group-buttons .btn:first-child{margin-left:0;border-top-left-radius:.25em;border-bottom-left-radius:.25em;padding-right:15px}
.ui-group-buttons .btn:last-child{border-top-right-radius:.25em;border-bottom-right-radius:.25em;padding-left:15px}



.icon-circle .fa{
  font-size           : 25px;
  color               : #e84700;
  margin              : 0 auto;
  height              : 40px;
  width               : 40px;
  border-radius       : 50%;
  border              :2px solid #e84700;
  line-height         : 30px;
  cursor              : pointer;
  -webkit-transition  : all  ease-in-out 0.35s;
  -moz-transition     : all  ease-in-out 0.35s;
  -o-transition       : all  ease-in-out 0.35s;
  -ms-transition      : all  ease-in-out 0.35s;
  transition          : all  ease-in-out 0.20s,background-color ease-in-out 0.05s;
}

.icon-circle  .fa:hover{

  background-color        : #e84700;
  color                   : #fff;
  border                  : 1px solid #e84711;
  -moz-box-shadow         : inset 0px 0px 0px 5px #ffffff;
  -o-box-shadow           : inset 0px 0px 0px 5px #ffffff;
  -ms-box-shadow          : inset 0px 0px 0px 5px #ffffff;
  -webkit-box-shadow      : inset 0px 0px 0px 5px #ffffff;
  box-shadow              : inset 0px 0px 0px 5px #ffffff;
  -ms-transform           : scale(1.2,1.2);
  -webkit-transform       : scale(1.2,1.2);
  -moz-transform          : scale(1.2,1.2);
  -o-transform            : scale(1.2,1.2);
  transform               : scale(1.2,1.2);
}

.icon-circle  i:before{
  margin-left         : 0px;
  font-size           : 15px;
}

@media (min-width:320px) and (max-width:768px) {


  .icon-circle .fa{
    font-size           : 15px;
    color               : #e84700;
    margin              : 0 auto;
    height              : 40px;
    width               : 40px;
    border-radius       : 50%;
    border              :2px solid #e84700;
    line-height         : 40px;
    cursor              : pointer;
    -webkit-transition  : all  ease-in-out 0.35s;
    -moz-transition     : all  ease-in-out 0.35s;
    -o-transition       : all  ease-in-out 0.35s;
    -ms-transition      : all  ease-in-out 0.35s;
    transition          : all  ease-in-out 0.20s,background-color ease-in-out 0.05s;
  }

  .icon-circle  .fa:hover{

    background-color        : #e84700;
    color                   : #fff;
    border                  : 1px solid #e84711;
    -moz-box-shadow         : inset 0px 0px 0px 5px #ffffff;
    -o-box-shadow           : inset 0px 0px 0px 5px #ffffff;
    -ms-box-shadow          : inset 0px 0px 0px 5px #ffffff;
    -webkit-box-shadow      : inset 0px 0px 0px 5px #ffffff;
    box-shadow              : inset 0px 0px 0px 5px #ffffff;
    -ms-transform           : scale(1.2,1.2);
    -webkit-transform       : scale(1.2,1.2);
    -moz-transform          : scale(1.2,1.2);
    -o-transform            : scale(1.2,1.2);
    transform               : scale(1.2,1.2);
  }

  .icon-circle  i:before{
    margin-left         : 0px;
    font-size           : 20px;
  }
}

.ifacebook .fa{
  color               :#3B5998;
  border              :2px solid #3B5998;
}

.ifacebook .fa:hover{

  background-color        : #3B5998;
  color                   : #fff;
  border                  : 1px solid #3B5998;

}

.itwittter .fa{
  color               : #33ccff;
  border              :2px solid #33ccff;
}

.itwittter .fa:hover{

  background-color        : #33ccff;
  color                   : #fff;
  border                  : 1px solid #33ccff;

}

.igoogle .fa{
  color               : #BD3518;
  border              :2px solid #BD3518;
}

.igoogle .fa:hover{

  background-color        : #BD3518;
  color                   : #fff;
  border                  : 1px solid #BD3518;

}

.iLinkedin .fa{
  color               : #007bb7;
  border              :2px solid #007bb7;
}

.iLinkedin .fa:hover{

  background-color        :#007bb7;
  color                   : #fff;
  border                  : 1px solid #007bb7;

}

.pagination {
  display: inline-block;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

.pagination>li>a, .pagination>li>span {
  position: relative;
  font-size: 16px;
  float: left;
  padding: 6px 12px;
  height: 35px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.badgebox
{
  opacity: 0;
}

.badgebox + .badge
{
  text-indent: -999999px;
  width: 27px;
}

.badgebox:focus + .badge
{
  box-shadow: inset 0px 0px 5px;
}

.badgebox:checked + .badge
{
  text-indent: 0;
}
.btn-primary {
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;

  &:hover {
    background: darken($primary-color, 10%);
  }
}

.btn-secondary {
  color: $primary-color;
  background-color: $secondary-color;
  border-color: transparent;
}