ul.discussions {
  list-style: none;
  margin: 0px;
  padding: 0px;
  background: #fff;
}

ul.discussions li a.discussion_list, ul.discussions li .chatter_posts {
  background: #ffffff;
  padding: 5px;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
  border-top: 1px solid #f1fcff;
  text-decoration: none;
  display: block;
  position: relative;

  &:hover {
    background: #f9f9f9;
  }
}

.chatter_avatar {
  float: left;
  margin: 3px;
  margin-right: 15px;
  position: absolute;
  left: 10px;
}

ul.discussions li a.discussion_list .chatter_middle, ul.discussions li .chatter_posts .chatter_middle {
  float: left;
  margin-left: 80px;
  margin-right: 90px;
}

ul.discussions li a.discussion_list .chatter_middle h3.chatter_middle_title, ul.discussions li .chatter_posts .chatter_middle h3.chatter_middle_title {
  margin: 0px;
  padding: 10px 0 0;
  color: #111;
  transition: color 0.3s ease;
  font-size: 15px;
  font-weight: bold;
  /* margin-top: 5px; */
  min-height: 24px;
}

ul.discussions li a.discussion_list .chatter_middle span.chatter_middle_details,  ul.discussions li .chatter_posts .chatter_middle span.chatter_middle_details {
  font-size: 10px;
  color: #9DADC4;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

ul.discussions li a.discussion_list .chatter_right, ul.discussions li .chatter_posts .chatter_right {
  float: right;
  right: 25px;
  text-align: right;
  position: absolute;
}

ul.discussions li a.discussion_list .chatter_clear, ul.discussions li .chatter_posts .chatter_clear {
  clear: both;
}

.chatter_avatar span.chatter_avatar_circle {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: $primary-color;
  display: inline-block;
  border-radius: 30px;
  color: #fff;
  font-size: 20px;
}

ul.discussions li a.discussion_list .chatter_middle h3.chatter_middle_title .chatter_cat, #chatter ul.discussions li .chatter_posts .chatter_middle h3.chatter_middle_title .chatter_cat {
  background: #ccc;
  border-radius: 30px;
  font-weight: bold;
  font-size: 10px;
  padding: 3px 7px;
  display: inline;
  color: #fff;
  position: relative;
  top: -2px;
}

.chatter-bubble:before {
  content: "\61";
}
ul.discussions li a.discussion_list .chatter_right .chatter_count, #chatter ul.discussions li .chatter_posts .chatter_right .chatter_count {
  padding: 8px 20px;
  border-radius: 3px;
  margin-top: 0px;
  font-size: 20px;
  color: #9DADB4;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
  font-family: Arial, Helvetica, Times, Courier;
}

ul.discussions li a.discussion_list .chatter_right .chatter_count i, #chatter ul.discussions li .chatter_posts .chatter_right .chatter_count i {
  position: relative;
  top: 2px;
}
[class^="chatter-"]:before, [class*=" chatter-"]:before {
  font-family: "chatter" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

